// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

$md-tcprimary: (
  50: #e0edff,
  100: #b3d1ff,
  200: #80b3ff,
  300: #4d94ff,
  400: #267dff,
  500: #0066ff,
  600: #0052cc,
  700: #0041a3,
  800: #00317a,
  900: #002051,
  A100: #ffffff,
  A200: #f2f4ff,
  A400: #bfc9ff,
  A700: #a6b4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-tcaccent: (
  50: #ffede0,
  100: #ffd1b3,
  200: #ffb380,
  300: #ff944d,
  400: #ff7d26,
  500: #ff6600,
  600: #ff5e00,
  700: #ff5300,
  800: #ff4900,
  900: #ff3800,
  A100: #ffffff,
  A200: #fff4f2,
  A400: #ffc9bf,
  A700: #ffb4a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$mat-light-theme-foreground-palette: (
  base: black,
  divider: $black-12-opacity,
  dividers: $black-12-opacity,
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.38),
  disabled-text: rgba(black, 0.38),
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.7),
);

$Taxcloud-Angular-primary: mat.define-palette($md-tcprimary);
$Taxcloud-Angular-accent: mat.define-palette($md-tcaccent, 900, 700, 500);
// safari zoom bug needs min input of 16px
$custom-typography: mat.define-typography-config(
  $display-1: mat.define-typography-level(1.3rem, 1.5rem, 400),
  $display-2: mat.define-typography-level(1.5rem, 2rem, 300),
  $display-3: mat.define-typography-level(2rem, 2.5rem, 300),
  $display-4: mat.define-typography-level(2.5rem, 3rem, 100),
  $input: mat.define-typography-level(16px, 16px, 500),
  $title: mat.define-typography-level(2.5rem, 3rem, 300),
  $subheading-2: mat.define-typography-level(1.5rem, 2rem, 400),
  $subheading-1: mat.define-typography-level(1rem, 1.2rem, 400),
);

$Taxcloud-Angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$Taxcloud-Angular-theme: mat.define-light-theme(
  $Taxcloud-Angular-primary,
  $Taxcloud-Angular-accent,
  $Taxcloud-Angular-warn
);

@include mat.all-component-themes($Taxcloud-Angular-theme);
@include mat.typography-hierarchy($custom-typography);

@include mat.slider-theme(
  map_merge(
    $Taxcloud-Angular-theme,
    (
      foreground: (
        base: #0066ff,
        slider-min: #ff3800,
        slider-off: #bebebe,
        slider-off-active: #bebebe,
      ),
    )
  )
);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Inter, 'Helvetica Neue', sans-serif;
}
