@import "primeicons/primeicons.css"

$small: var(--small)
$medium: var(--medium)
$x-small: var(--x-small)
$primary-color:#0066FF
$accent-color: #ff6600
$error-color: #f44336
$success-color: #4CAF50
$warning-color: #ffb900
$question-color: #212121
$disabled-color: rgba(black, 0.18)
$disabled-background-color: rgba(black, 0.08)
$information-color: rgb(63, 81, 181)
$secondary-color: #9E9E9E
$hover-color: #919191
$primary-stripe-color: #7d7d7d

$primary-background-color: #e8ebf7
$small-change: 4%
$light-change: 40%
$lightest-change: 46%

:root 
    --primary-color: #0066FF
    --primary-color-200: #80b3ff
    --primary-color-300: #4d94ff
    --primary-text-color: #616161
    --primary-background-color: #fff
    --title-text-color: #212121
    --divider-color: #E0E0E0
    --hover-color: #F5F5F5
    --accent-color: #ff6600
    --accent-color-900: #ff3800
    --accent-color-400: #E68545
    --accent-color-300: #ff944d
    --accent-text-color: #ff3800
    --go-live-color: #19fa04
    --error-color: #f44336
    --error-text-color: var(--error-color)
    --error-background-color: #ffebee
    --success-color: #4CAF50
    --success-text-color: var(--success-color)
    --success-background-color: #eff8ef
    --secondary-color: #9E9E9E
    --warning-color: #ffb900
    --warning-text-color: #F57F17
    --warning-background-color: #FFF9C4
    --question-text-color: var(--primary-text-color)
    --question-color: var(--primary-text-color)
    --question-background-color: var(--hover-color)
    --information-color: var(--primary-color)
    --information-text-color: var(--primary-color)
    --information-background-color: #e8ebf7
    --disabled-color: rgba(black, 0.18)
    --disabled-text-color: rgba(black, 0.18)
    --disabled-background-color: rgba(black, 0.08)
    --danger-text-color: var(--error-color)
    --negative-text-color: var(--error-color)

    --x-small: .33rem
    --small: 1rem
    --medium: 2rem
    
a
    color: var(--primary-color)

input
    font-size: 16px !important

.mat-sidenav-container
    height: 100%

.square 
  height: 2rem
  width: 2rem
  border-radius: 5px

.darken-background
    background-color: var(--hover-color)

.blog img
    width: 100%
    height: auto

.blog p, 
.integrations-page p,
.support-page p,
.about-page p
    display: block
    margin-block-start: $small
    margin-block-end: $small
    margin-inline-start: 0px
    margin-inline-end: 0px
    font-size: 1.2rem
    font-weight: 300
    color: var(--primary-text-color)
    line-height: 1.6rem

.blog blockquote, 
.integrations-page blockquote,
.support-page blockquote,
.about-page blockquote
    background-image: linear-gradient(60deg, lighten($primary-color, $lightest-change), lighten($primary-color, $light-change))
    color: var(--information-text-color)
    margin: unset
    margin: $medium
    font-size: 1.8rem
    font-weight: 300
    text-align: center
    padding: $medium
    border-radius: 3px
    border-left: 5px solid var(--information-text-color)

.blog blockquote p, 
.integrations-page blockquote p,
.support-page blockquote p,
.about-page blockquote p
    margin: unset
    color: var(--information-text-color)
    font-size: 1.8rem
    line-height: 2rem
    font-weight: 300
    text-align: center

.new-tag
    color: var(--success-color)
    font-weight: 700
    line-height: 1.5rem
    font-size: 1.2rem

.new-tag-1
    color: var(--success-color)
    font-weight: 700
    font-size: 2rem

section 
    width: 100%
    max-width: 1200px
    margin: 16px auto $medium auto 

h1
    line-height: 2.5rem
    font-size: 2rem
    font-weight: 400 !important
    
h2
    line-height: 2rem
    font-size: 1.5rem
    font-weight: 300 !important
    color: rgba(black, .7)

h3
    color: var(--primary-color)
    line-height: 1.5rem
    font-size: 1.3rem
    font-weight: 400 !important
    color: rgba(black, .7)

p:last-child 
    margin-bottom: unset

table
    width: 100%
    min-width: 600px
    // border-collapse: collapse
    // border: 1px solid var(--divider-color)

th, td 
    padding: var(--small)
    text-align: left

th
    line-height: 1.5rem
    font-size: 1.3rem
    font-weight: 400 !important

// table tr:nth-child(even) 
    // background-image: linear-gradient(60deg, lighten($primary-stripe-color, $lightest-change), lighten($primary-stripe-color, $light-change))

.information table
    border-collapse: collapse
    border: 1px solid var(--divider-color)

.information table tr:nth-child(even)
    background-image: linear-gradient(60deg, lighten($primary-color, $lightest-change), lighten($primary-color, $light-change))

.information table thead
    background-image: linear-gradient(60deg, lighten($primary-color, $small-change), darken($primary-color, $small-change))
    color: white

.tos table
    border-collapse: collapse
    margin-top: $medium !important
    margin-bottom: $medium !important
    border: 1px solid var(--divider-color)

.tos table tr:nth-child(even)
    background-image: linear-gradient(60deg, lighten($primary-color, $lightest-change), lighten($primary-color, $light-change))

.tos table thead
    background-image: linear-gradient(60deg, lighten($primary-color, $small-change), darken($primary-color, $small-change))
    color: white

.tos h4
    font-size: 16px
    font-weight: 600
    line-height: 20px
    margin-top: $medium !important

.tos ul,
.tos ol,
.tos li,
.tos hr,
.tos p
    margin-top: $small !important
    margin-bottom: $small !important


.mat-row
  height: auto

.mat-cell
  padding: 0 var(--small) !important

.mat-header-cell
  padding: 0 var(--small) !important

.mat-footer-cell
  padding: 0 var(--small) !important
  

// .mat-table-sticky:first-child 
//   border-right: 1px solid var(--divider-color)

// .mat-table-sticky:last-child 
//   border-left: 1px solid var(--divider-color)

._required:after
    content: "*"
    color: red
    margin-left: 6px
    font-size: 20px
    vertical-align: middle

.mat-display-1, .mat-display-2, .mat-display-3, .mat-display-4
    margin: 1rem 0 1rem 0 !important
    color: var(--primary-text-color)

.circle 
    border-radius: 84px
    border: solid 3px var(--primary-color)
    width: 84px
    height: 84px

.circle mat-icon
    color: var(--primary-color)
    font-size: 60px
    margin: 12px 0 0 12px

.confirm-dialog  
    mat-dialog-container
        padding: 0
        min-width: 50vw
        border-radius: 3px

.single-card-page
    max-width: 500px
    margin: var(--small) auto

mat-card-title
    color: var(--title-text-color)
    font-size: 1.4rem
    font-weight: 600
    line-height: 1.4rem

mat-card-actions >  *
    margin-right: var(--small)

.cdk-overlay-container
    position: fixed
    z-index: 10000

.fab
    position: fixed
    right: 24px
    bottom: 67px
    margin-bottom: 0
    z-index: 900

.full-width
    width: 100%

.success-message
    color: white
    background-color: var(--success-color)

.error-message
    color: white
    background-color: var(--error-color)

.margin_sm
    margin: $small
.margin_h_sm
    margin-left: $small
    margin-right: $small
.margin_v_sm
    margin-top: $small
    margin-bottom: $small
.margin_t_sm
    margin-top: $small
.margin_r_sm
    margin-right: $small
.margin_b_sm
    margin-bottom: $small
.margin_l_sm
    margin-left: $small

.margin_md
    margin: $medium
.margin_h_md
    margin-left: $medium
    margin-right: $medium
.margin_v_md
    margin-top: $medium
    margin-bottom: $medium
.margin_r_md
    margin-right: $medium
.margin_b_md
    margin-bottom: $medium
.margin_l_md
    margin-left: $medium

.padding_xsm
    padding: $x-small
.padding_h_xsm
    padding-left: $x-small
    padding-right: $x-small
.padding_v_xsm
    padding-top: $x-small
    padding-bottom: $x-small
.padding_t_xsm
    padding-top: $x-small
.padding_r_xsm
    padding-right: $x-small
.padding_b_xsm
    padding-bottom: $x-small
.padding_l_xsm
    padding-left: $x-small

.padding_sm
    padding: $small
.padding_h_sm
    padding-left: $small
    padding-right: $small
.padding_v_sm
    padding-top: $small
    padding-bottom: $small
.padding_t_sm
    padding-top: $small
.padding_r_sm
    padding-right: $small
.padding_b_sm
    padding-bottom: $small
.padding_l_sm
    padding-left: $small

.tc_basic_border
    border: 1px solid

button .dark 
    background-color: white
    color: var(--primary-color)

.primary-background, .information-background
    background-image: linear-gradient(60deg, lighten($primary-color, $lightest-change), lighten($primary-color, $light-change))

.success-background
    background-image: linear-gradient(60deg, lighten($success-color, $lightest-change), lighten($success-color, $light-change))

.warning-background
    background-image: linear-gradient(60deg, lighten($warning-color, $lightest-change), lighten($warning-color, $light-change))

.error-background, .danger-background
    background-image: linear-gradient(60deg, lighten($error-color, 38%), lighten($error-color, 35%))

.question-background
    background-image: linear-gradient(60deg, lighten($question-color, 80%), lighten($question-color, 76%))

.accent-background
    background-image: linear-gradient(60deg, lighten($accent-color, $lightest-change), lighten($accent-color, $light-change))

.secondary-background
    background-image: linear-gradient(60deg, lighten($secondary-color, $lightest-change), lighten($secondary-color, $light-change))

.hover-background
    background-image: linear-gradient(60deg, lighten($hover-color, $lightest-change), lighten($hover-color, $light-change))

.primary-background-dark, .information-background-dark
    background-image: linear-gradient(60deg, lighten($primary-color, $small-change), darken($primary-color, $small-change))

.success-background-dark
    background-image: linear-gradient(60deg, lighten($success-color, $small-change), darken($success-color, $small-change))

.warning-background-dark
    background-image: linear-gradient(60deg, lighten($warning-color, $small-change), darken($warning-color, $small-change))

.error-background-dark, .danger-background-dark
    background-image: linear-gradient(60deg, lighten($error-color, $small-change), darken($error-color, $small-change))

.question-background-dark
    background-image: linear-gradient(60deg, lighten($question-color, $small-change), darken($question-color, $small-change))

.accent-background-dark
    background-image: linear-gradient(60deg, lighten($accent-color, $small-change), darken($accent-color, $small-change))

.secondary-background-dark, .disabled-background-dark
    background-image: linear-gradient(60deg, lighten($secondary-color, $small-change), darken($secondary-color, $small-change))

.disabled-background
    background-image: linear-gradient(60deg, lighten($disabled-background-color, $small-change), lighten($disabled-background-color, $small-change))

#hubspot-messages-iframe-container
    display: none !important
