.p-component
    font-family: "Inter", Inter, 'Helvetica Neue', sans-serif

.p-message.p-message-info,
.p-inline-message.p-inline-message-info
    background: rgba(219, 234, 254, 0.7)
    border: solid #3b82f6
    border-width: 0 0 0 6px
    color: #3b82f6

    .p-inline-message-icon
        color: #3b82f6

.ng-invalid.ng-touched > .p-dropdown,
.ng-invalid.ng-touched > .p-calendar > .p-inputtext
    border: 1px solid #E24C4C
